<template>
  <div id="homepage-1">
    <div
      class="ps-container shop-direct"
      style="display:none; background-color: white; padding: 20px; color: black; top: 0; width: 100%; right: 0;"
    >
      <div class="container">
        <h5 class="banner-heading">
          <span class="banner-heading--1">Shop DIRECTLY from</span>
          <span class="banner-heading--2">Zimbabwe's MERCHANTS</span>
        </h5>
        <h4 class="mt-2 banner-heading banner-title">
          INSTANT or SCHEDULED pickup or delivery across Zimbabwe.
        </h4>
      </div>
      <!-- <a class="ps-btn" href="#">ShopNowPH</a> -->
    </div>

    <div class="row ls-hero-banner">
      <div class="col-12 ls-center">
        <img
          class=""
          style="width:300px; height:auto;"
          src="/img/logo.png"
          alt="Logo"
        />
      </div>
      <div class="col-12 ls-center">
        <h1 style="color:white; padding-bottom:60px">
          One Stop Zimbabwe!
        </h1>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-md-2 col-2"></div>
          <div class="col-md-8 col-12">
            <HeaderSearch></HeaderSearch>
          </div>
          <div class="col-md-2 col-2"></div>
        </div>
      </div>
    </div>
    <HomeSlideshow v-if="false"></HomeSlideshow>

    <div class="ls-section"></div>
    <div class="ps-section__header ls-section-header">
      <h2>Shop by category from manufacturers, wholesalers, retailers</h2>
    </div>

    <div class="category-container desktop-categories ls-quick-links-bar"></div>
    <div class="row ls-quick-links-bar">
      <div class="col-lg-4 col-6">
        <a
          href="/products/takeaways-restaurants"
          class="category-item ls-cat-item"
        >
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(4.73,4.73) scale(0.945,0.945)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#000000"
                    stroke="#757575"
                    stroke-width="10"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M86,17.2c1.892,0 3.44,1.548 3.44,3.44c0,1.892 -1.548,3.44 -3.44,3.44h-18.67813l-4.13203,6.88h-8.04906l7.26297,-12.07359c0.6192,-1.0664 1.75225,-1.68641 2.95625,-1.68641zM61.92,34.4c5.676,0 10.32,4.644 10.32,10.32v2.02906l4.88453,4.85094h-35.84453c-1.892,0 -3.44,1.548 -3.44,3.44c0,1.892 1.548,3.44 3.44,3.44h37.84v37.84h-20.64c-7.6024,0 -13.76,6.1576 -13.76,13.76v3.44h-3.44c-1.892,0 -3.44,1.548 -3.44,3.44c0,1.892 1.548,3.44 3.44,3.44h3.44v13.76h-20.64c-3.784,0 -6.88,-3.096 -6.88,-6.88v-2.02906l-4.88453,-4.85094h15.20453c1.892,0 3.44,-1.548 3.44,-3.44c0,-1.892 -1.548,-3.44 -3.44,-3.44h-17.2v-55.04h17.2c1.892,0 3.44,-1.548 3.44,-3.44c0,-1.892 -1.548,-3.44 -3.44,-3.44h-15.20453l4.88453,-4.85094v-2.02906c0,-5.676 4.644,-10.32 10.32,-10.32zM151.36,103.2c5.676,0 10.32,4.644 10.32,10.32v30.96c0,5.676 -4.644,10.32 -10.32,10.32h-89.44c-5.676,0 -10.32,-4.644 -10.32,-10.32v-30.96c0,-5.676 4.644,-10.32 10.32,-10.32zM58.48,134.16h8.77469c2.408,4.1968 6.91171,6.88 11.86531,6.88c3.956,0 7.67281,-1.71865 10.25281,-4.64265c4.4032,2.1328 10.0757,1.23705 13.6189,-2.23735h21.05656c4.472,4.4032 12.31144,4.4032 16.78344,0h13.96828v-10.32h-96.32z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a51e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M65.36,17.2c-1.204,0 -2.33705,0.62001 -2.95625,1.68641l-7.26297,12.07359h8.04906l4.13203,-6.88h18.67813c1.892,0 3.44,-1.548 3.44,-3.44c0,-1.892 -1.548,-3.44 -3.44,-3.44zM27.52,34.4c-5.676,0 -10.32,4.644 -10.32,10.32v2.02906l-4.88453,4.85094h15.20453c1.892,0 3.44,1.548 3.44,3.44c0,1.892 -1.548,3.44 -3.44,3.44h-17.2v55.04h17.2c1.892,0 3.44,1.548 3.44,3.44c0,1.892 -1.548,3.44 -3.44,3.44h-15.20453l4.88453,4.85094v2.02906c0,3.784 3.096,6.88 6.88,6.88h20.64v-13.76h-3.44c-1.892,0 -3.44,-1.548 -3.44,-3.44c0,-1.892 1.548,-3.44 3.44,-3.44h3.44v-3.44c0,-7.6024 6.1576,-13.76 13.76,-13.76h20.64v-37.84h-37.84c-1.892,0 -3.44,-1.548 -3.44,-3.44c0,-1.892 1.548,-3.44 3.44,-3.44h35.84453l-4.88453,-4.85094v-2.02906c0,-5.676 -4.644,-10.32 -10.32,-10.32h-0.79281h-8.04906zM61.92,103.2c-5.676,0 -10.32,4.644 -10.32,10.32v6.88v24.08c0,5.676 4.644,10.32 10.32,10.32h89.44c5.676,0 10.32,-4.644 10.32,-10.32v-30.96c0,-5.676 -4.644,-10.32 -10.32,-10.32zM58.48,123.84h96.32v10.32h-13.96828c-4.472,4.4032 -12.31144,4.4032 -16.78344,0h-21.05656c-3.5432,3.4744 -9.2157,4.37015 -13.6189,2.23735c-2.58,2.924 -6.29681,4.64265 -10.25281,4.64265c-4.9536,0 -9.45731,-2.6832 -11.86531,-6.88h-8.77469z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title  ls-cat-title">Takeaways</h6>
        </a>
      </div>
      <div class="col-lg-4 col-6">
        <a
          class="category-item ls-cat-item"
          href="/products/pharmacies-health-care p-3"
        >
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(5.676,5.676) scale(0.934,0.934)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#f9a51e"
                    stroke="#757575"
                    stroke-width="12"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M134.73333,22.93333c11.08253,0 20.06667,8.98413 20.06667,20.06667c0,11.08253 -8.98413,20.06667 -20.06667,20.06667h-40.13333c-11.08253,0 -20.06667,-8.98413 -20.06667,-20.06667c0,-11.08253 8.98413,-20.06667 20.06667,-20.06667zM86,43c0,4.74147 3.85853,8.6 8.6,8.6h20.06667v-17.2h-20.06667c-4.74147,0 -8.6,3.85853 -8.6,8.6zM53.08932,81.87917h0.06719l49.84193,21.73516v0.0112c3.53879,1.15344 5.93396,4.45245 5.93489,8.17448c0,4.74965 -3.85035,8.6 -8.6,8.6h8.6l39.34948,-16.38255v0.0112c1.14124,-0.54091 2.3876,-0.82382 3.65052,-0.82864c4.74965,0 8.6,3.85035 8.6,8.6c-0.00543,3.11775 -1.69782,5.98839 -4.42317,7.50261l0.0112,0.0112l-51.52161,28.62187c-0.00048,0.00024 -0.03284,-0.00025 -0.0336,0c-1.28963,0.73611 -2.74789,1.12575 -4.23281,1.13099c-1.28353,-0.00353 -2.54999,-0.29432 -3.70651,-0.85104c-0.00013,0.00001 -0.0224,0 -0.0224,0l-51.96953,-21.23124c-2.88387,-1.18107 -6.11433,-1.13762 -8.96953,0.10078l-24.1987,10.51484v-45.86667l27.99479,-10.2237c2.03621,-0.8192 4.21039,-1.24111 6.40521,-1.24297c2.49603,0.00706 4.96063,0.5573 7.22266,1.6125z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a51e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M94.6,22.93333c-11.08253,0 -20.06667,8.98413 -20.06667,20.06667c0,11.08253 8.98413,20.06667 20.06667,20.06667h40.13333c11.08253,0 20.06667,-8.98413 20.06667,-20.06667c0,-11.08253 -8.98413,-20.06667 -20.06667,-20.06667zM94.6,34.4h20.06667v17.2h-20.06667c-4.74147,0 -8.6,-3.85853 -8.6,-8.6c0,-4.74147 3.85853,-8.6 8.6,-8.6zM45.86667,80.26667c-2.19482,0.00186 -4.369,0.42377 -6.40521,1.24297l-27.99479,10.2237v45.86667l24.1987,-10.51484c2.8552,-1.2384 6.08567,-1.28185 8.96953,-0.10078l51.96953,21.23124c0,0 0.02227,0.00001 0.0224,0c1.15651,0.55672 2.42298,0.84751 3.70651,0.85104c1.48492,-0.00524 2.94318,-0.39489 4.23281,-1.13099c0.00076,-0.00025 0.03312,0.00024 0.0336,0l51.52161,-28.62187l-0.0112,-0.0112c2.72535,-1.51422 4.41774,-4.38486 4.42317,-7.50261c0,-4.74965 -3.85035,-8.6 -8.6,-8.6c-1.26292,0.00482 -2.50929,0.28774 -3.65052,0.82864v-0.0112l-39.34948,16.38255h-8.6c4.74965,0 8.6,-3.85035 8.6,-8.6c-0.00093,-3.72202 -2.3961,-7.02104 -5.93489,-8.17448v-0.0112l-49.84193,-21.73516h-0.06719c-2.26203,-1.0552 -4.72662,-1.60544 -7.22266,-1.6125z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title ls-cat-title">Pharmacies &amp; Health</h6>
        </a>
      </div>
      <div class="col-lg-4 col-6">
        <a
          class="category-item ls-cat-item"
          href="/products/groceries-and-fresh-food p-3"
        >
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(4.214,4.214) scale(0.951,0.951)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#f9a51e"
                    stroke="#757575"
                    stroke-width="9"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M120.4,5.73333c3.1648,0 5.73333,2.56853 5.73333,5.73333c0,2.08347 -1.16942,3.83358 -2.83308,4.8375l9.39505,15.65469c3.20493,5.34347 4.90469,11.46604 4.90469,17.70391v1.93724h-51.6v-1.93724c0,-6.23213 1.69402,-12.36044 4.90469,-17.70391l9.39505,-15.65469c-1.66365,-1.00392 -2.83308,-2.75403 -2.83308,-4.8375c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333zM81.37526,25.54245l-0.30234,0.5263c-2.3736,3.95027 -4.06171,8.23271 -5.15104,12.67604l-16.86407,-11.24271c3.4572,-2.81507 7.80432,-4.56875 12.60886,-4.56875c3.5432,0 6.81899,0.99805 9.70859,2.60911zM45.72109,32.38438l28.81224,19.21563h-45.86667l8.46562,-16.93125c1.1954,-2.3908 3.75766,-3.5853 6.21484,-3.2026c0.81906,0.12757 1.63293,0.42516 2.37396,0.91823zM154.8,63.06667c3.1648,0 5.73333,2.56853 5.73333,5.73333v11.46667c0,3.1648 -2.56853,5.73333 -5.73333,5.73333h-0.95183l-8.92474,53.48125c-0.92307,5.52693 -5.70269,9.58542 -11.30989,9.58542h-95.23828c-5.60147,0 -10.38683,-4.05848 -11.3099,-9.58542l-8.91354,-53.48125h-0.95182c-3.1648,0 -5.73333,-2.56853 -5.73333,-5.73333v-11.46667c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333zM57.33333,91.73333v34.4c0,3.17053 2.5628,5.73333 5.73333,5.73333c3.17053,0 5.73333,-2.5628 5.73333,-5.73333v-34.4c0,-3.17053 -2.5628,-5.73333 -5.73333,-5.73333c-3.17053,0 -5.73333,2.5628 -5.73333,5.73333zM80.26667,91.73333v34.4c0,3.17053 2.5628,5.73333 5.73333,5.73333c3.17053,0 5.73333,-2.5628 5.73333,-5.73333v-34.4c0,-3.17053 -2.5628,-5.73333 -5.73333,-5.73333c-3.17053,0 -5.73333,2.5628 -5.73333,5.73333zM103.2,91.73333v34.4c0,3.17053 2.5628,5.73333 5.73333,5.73333c3.17053,0 5.73333,-2.5628 5.73333,-5.73333v-34.4c0,-3.17053 -2.5628,-5.73333 -5.73333,-5.73333c-3.17053,0 -5.73333,2.5628 -5.73333,5.73333z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a51e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M103.2,5.73333c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333c0,2.08347 1.16942,3.83358 2.83308,4.8375l-9.39505,15.65469c-3.21067,5.34347 -4.90469,11.47177 -4.90469,17.70391v1.93724h51.6v-1.93724c0,-6.23787 -1.69976,-12.36044 -4.90469,-17.70391l-9.39505,-15.65469c1.66365,-1.00392 2.83308,-2.75403 2.83308,-4.8375c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333zM71.66667,22.93333c-4.80453,0 -9.15166,1.75368 -12.60886,4.56875l16.86407,11.24271c1.08933,-4.44333 2.77744,-8.72577 5.15104,-12.67604l0.30234,-0.5263c-2.8896,-1.61107 -6.16539,-2.60911 -9.70859,-2.60911zM43.34714,31.46615c-2.45718,-0.3827 -5.01944,0.8118 -6.21484,3.2026l-8.46562,16.93125h45.86667l-28.81224,-19.21563c-0.74103,-0.49307 -1.5549,-0.79066 -2.37396,-0.91823zM17.2,63.06667c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333v11.46667c0,3.1648 2.56853,5.73333 5.73333,5.73333h0.95182l8.91354,53.48125c0.92307,5.52693 5.70843,9.58542 11.3099,9.58542h95.23828c5.6072,0 10.38683,-4.05848 11.30989,-9.58542l8.92474,-53.48125h0.95183c3.1648,0 5.73333,-2.56853 5.73333,-5.73333v-11.46667c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333zM63.06667,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333zM86,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333zM108.93333,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title ls-cat-title">Groceries</h6>
        </a>
      </div>
      <div class="col-lg-4 col-6">
        <a
          class="category-item ls-cat-item"
          href="/products/treats-gifts-and-alcohol p-3"
        >
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(5.59,5.59) scale(0.935,0.935)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#f9a51e"
                    stroke="#757575"
                    stroke-width="12"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M59.125,21.5v17.38477l18.72852,-11.24341l5.54297,9.21729l-10.2356,6.14136h18.21411v5.9209c-1.70925,-0.3225 -3.483,-0.5459 -5.375,-0.5459c-5.9985,0 -11.50065,2.0425 -15.97803,5.375h-43.14697v80.625h21.5v10.75h-32.25v-102.125h18.21411l-10.2356,-6.14136l5.54297,-9.21729l18.72852,11.24341v-17.38477zM105.98828,72.22656c0.56437,0.80087 1.00647,1.58268 1.51172,2.36206c0.49988,-0.77938 0.94734,-1.56656 1.51172,-2.36206c4.19787,-5.96625 10.58203,-13.10156 19.98828,-13.10156c8.84187,0 16.125,7.28312 16.125,16.125c0,1.892 -0.39506,3.6765 -1.00781,5.375h11.75781v64.5h-96.75v-64.5h11.75781c-0.60737,-1.6985 -1.00781,-3.483 -1.00781,-5.375c0,-8.84188 7.28312,-16.125 16.125,-16.125c9.40625,0 15.78503,7.13531 19.98828,13.10156zM80.625,75.2605c0,3.04225 2.33275,5.375 5.375,5.375h12.42969c-0.65037,-1.118 -0.52675,-1.18493 -1.34375,-2.36206c-3.35937,-4.78912 -7.72656,-8.39844 -11.08594,-8.39844c-3.04225,0 -5.375,2.34325 -5.375,5.3855zM117.91406,78.27344c-0.817,1.17175 -0.688,1.23894 -1.34375,2.35156h12.42969c3.04225,0 5.375,-2.33275 5.375,-5.375c0,-3.04225 -2.33275,-5.375 -5.375,-5.375c-3.35937,0 -7.72656,3.60931 -11.08594,8.39844zM69.875,134.375h32.25v-43h-32.25zM112.875,134.375h32.25v-43h-32.25z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a51e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M48.375,21.5v17.38477l-18.72852,-11.24341l-5.54297,9.21729l10.2356,6.14136h-18.21411v102.125h32.25v-10.75h-21.5v-80.625h43.14697c4.47737,-3.3325 9.97953,-5.375 15.97803,-5.375c1.892,0 3.66575,0.2234 5.375,0.5459v-5.9209h-18.21411l10.2356,-6.14136l-5.54297,-9.21729l-18.72852,11.24341v-17.38477zM86,59.125c-8.84188,0 -16.125,7.28312 -16.125,16.125c0,1.892 0.40044,3.6765 1.00781,5.375h-11.75781v10.75v53.75h96.75v-53.75v-10.75h-11.75781c0.61275,-1.6985 1.00781,-3.483 1.00781,-5.375c0,-8.84188 -7.28313,-16.125 -16.125,-16.125c-9.40625,0 -15.79041,7.13531 -19.98828,13.10156c-0.56437,0.7955 -1.01184,1.58268 -1.51172,2.36206c-0.50525,-0.77938 -0.94734,-1.56118 -1.51172,-2.36206c-4.20325,-5.96625 -10.58203,-13.10156 -19.98828,-13.10156zM86,69.875c3.35938,0 7.72656,3.60931 11.08594,8.39844c0.817,1.17713 0.69338,1.24406 1.34375,2.36206h-12.42969c-3.04225,0 -5.375,-2.33275 -5.375,-5.375c0,-3.04225 2.33275,-5.3855 5.375,-5.3855zM129,69.875c3.04225,0 5.375,2.33275 5.375,5.375c0,3.04225 -2.33275,5.375 -5.375,5.375h-12.42969c0.65575,-1.11263 0.52675,-1.17981 1.34375,-2.35156c3.35938,-4.78912 7.72656,-8.39844 11.08594,-8.39844zM69.875,91.375h32.25v43h-32.25zM112.875,91.375h32.25v43h-32.25z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title ls-cat-title">Treats</h6>
        </a>
      </div>
      <div class="col-lg-4 col-6">
        <a
          class="category-item ls-cat-item"
          href="/products/utilities-funerals-insurances p-3"
        >
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(4.214,4.214) scale(0.951,0.951)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#f9a51e"
                    stroke="#757575"
                    stroke-width="9"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M120.0042,20.64068c0.92166,2.17779 0.4118,4.69802 -1.28389,6.3463l-24.61302,24.61302l26.29271,26.29271l24.61302,-24.61302c1.03843,-1.06877 2.45215,-1.69129 3.94167,-1.73568c2.36376,-0.06971 4.52786,1.31888 5.44951,3.49667c0.92166,2.17779 0.4118,4.69801 -1.28389,6.3463l-24.61302,24.61302l18.87969,18.87969c1.49778,1.43802 2.10113,3.5734 1.57735,5.5826c-0.52378,2.0092 -2.09284,3.57826 -4.10204,4.10204c-2.0092,0.52378 -4.14458,-0.07957 -5.5826,-1.57735l-22.09349,-22.09349c-0.66065,-0.43243 -1.22486,-0.99665 -1.65729,-1.65729l-32.74271,-32.74271c-0.66065,-0.43243 -1.22486,-0.99665 -1.65729,-1.65729l-22.11589,-22.11589c-1.6719,-1.62719 -2.19121,-4.10513 -1.31331,-6.26668c0.8779,-2.16155 2.97794,-3.57567 5.31096,-3.57628c1.54801,0.00009 3.03022,0.62609 4.10964,1.73568l18.87969,18.87969l24.61302,-24.61302c1.03843,-1.06877 2.45215,-1.69129 3.94167,-1.73568c2.36376,-0.06971 4.52786,1.31888 5.44951,3.49667zM127.73464,116.93984l-23.72839,23.72839c-11.1972,11.1972 -29.35046,11.1972 -40.54766,0l-1.16458,-1.16458l-1.63489,1.6237c-5.46387,5.46387 -14.31165,5.46387 -19.77552,0l-0.95182,-0.95182l-12.94479,12.94479c-1.43802,1.49776 -3.57339,2.1011 -5.58258,1.57732c-2.00919,-0.52378 -3.57824,-2.09283 -4.10202,-4.10202c-0.52378,-2.00919 0.07955,-4.14456 1.57731,-5.58258l12.94479,-12.94479l-0.94062,-0.94062c-5.46387,-5.46387 -5.46387,-14.32285 0,-19.78672l1.6237,-1.6237l-1.17578,-1.17578c-11.1972,-11.1972 -11.1972,-29.35046 0,-40.54766l23.72838,-23.72839zM51.6,86c0,3.1648 2.56853,5.73333 5.73333,5.73333c3.1648,0 5.73333,-2.56853 5.73333,-5.73333c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333zM80.26667,114.66667c0,3.1648 2.56853,5.73333 5.73333,5.73333c3.1648,0 5.73333,-2.56853 5.73333,-5.73333c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a51e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M114.55469,17.14401c-1.48951,0.04438 -2.90324,0.6669 -3.94167,1.73568l-24.61302,24.61302l-18.87969,-18.87969c-1.07942,-1.10959 -2.56163,-1.73559 -4.10964,-1.73568c-2.33303,0.00061 -4.43306,1.41473 -5.31096,3.57628c-0.8779,2.16155 -0.3586,4.6395 1.31331,6.26668l22.11589,22.11589c0.43243,0.66065 0.99664,1.22486 1.65729,1.65729l32.74271,32.74271c0.43243,0.66065 0.99664,1.22486 1.65729,1.65729l22.09349,22.09349c1.43802,1.49778 3.5734,2.10113 5.5826,1.57735c2.0092,-0.52378 3.57826,-2.09284 4.10204,-4.10204c0.52378,-2.0092 -0.07957,-4.14458 -1.57735,-5.5826l-18.87969,-18.87969l24.61302,-24.61302c1.69569,-1.64828 2.20555,-4.16851 1.28389,-6.3463c-0.92166,-2.17779 -3.08575,-3.56638 -5.44951,-3.49667c-1.48951,0.04438 -2.90324,0.6669 -3.94167,1.73568l-24.61302,24.61302l-26.29271,-26.29271l24.61302,-24.61302c1.69569,-1.64828 2.20555,-4.16851 1.28389,-6.3463c-0.92166,-2.17779 -3.08576,-3.56638 -5.44951,-3.49667zM55.06016,44.26536l-23.72838,23.72839c-11.1972,11.1972 -11.1972,29.35046 0,40.54766l1.17578,1.17578l-1.6237,1.6237c-5.46387,5.46387 -5.46387,14.32285 0,19.78672l0.94062,0.94062l-12.94479,12.94479c-1.49776,1.43802 -2.1011,3.57339 -1.57731,5.58258c0.52378,2.00919 2.09283,3.57824 4.10202,4.10202c2.00919,0.52378 4.14456,-0.07955 5.58258,-1.57732l12.94479,-12.94479l0.95182,0.95182c5.46387,5.46387 14.31165,5.46387 19.77552,0l1.63489,-1.6237l1.16458,1.16458c11.1972,11.1972 29.35046,11.1972 40.54766,0l23.72839,-23.72839zM57.33333,80.26667c3.1648,0 5.73333,2.56853 5.73333,5.73333c0,3.1648 -2.56853,5.73333 -5.73333,5.73333c-3.1648,0 -5.73333,-2.56853 -5.73333,-5.73333c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333zM86,108.93333c3.1648,0 5.73333,2.56853 5.73333,5.73333c0,3.1648 -2.56853,5.73333 -5.73333,5.73333c-3.1648,0 -5.73333,-2.56853 -5.73333,-5.73333c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title ls-cat-title">Bills/Fees</h6>
        </a>
      </div>
      <div class="col-lg-4 col-6">
        <a
          class="category-item ls-cat-item"
          href="/products/building-materials-hardware p-3"
        >
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(5.59,5.59) scale(0.935,0.935)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#f9a51e"
                    stroke="#757575"
                    stroke-width="12"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M165.12,48.16c0,22.76313 -18.51687,41.28 -41.28,41.28c-5.09281,0 -10.06469,-0.92719 -14.82156,-2.76812l-73.54344,73.54344c-3.26531,3.26531 -7.55187,4.89125 -11.85187,4.89125c-4.28656,0 -8.57313,-1.62594 -11.83844,-4.89125c-3.15781,-3.17125 -4.90469,-7.36375 -4.90469,-11.83844c0,-4.48813 1.74688,-8.69406 4.90469,-11.85188l73.55688,-73.54344c-1.85438,-4.75688 -2.78156,-9.72875 -2.78156,-14.82156c0,-22.76312 18.51688,-41.28 41.28,-41.28c4.36719,0 8.7075,0.69875 12.92688,2.08281l5.29437,1.74687l-27.93656,27.95l5.4825,13.73313l13.71969,5.4825l27.95,-27.93656l1.74687,5.29438c1.3975,4.21937 2.09625,8.55969 2.09625,12.92687z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a51e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M163.02375,35.23313l-1.74687,-5.29438l-27.95,27.93656l-13.71969,-5.4825l-5.4825,-13.73313l27.93656,-27.95l-5.29437,-1.74687c-4.21938,-1.38406 -8.55969,-2.08281 -12.92688,-2.08281c-22.76312,0 -41.28,18.51688 -41.28,41.28c0,5.09281 0.92719,10.06469 2.78156,14.82156l-73.55688,73.54344c-3.15781,3.15781 -4.90469,7.36375 -4.90469,11.85188c0,4.47469 1.74688,8.66719 4.90469,11.83844c3.26531,3.26531 7.55188,4.89125 11.83844,4.89125c4.3,0 8.58656,-1.62594 11.85187,-4.89125l73.54344,-73.54344c4.75688,1.84094 9.72875,2.76812 14.82156,2.76812c22.76313,0 41.28,-18.51687 41.28,-41.28c0,-4.36719 -0.69875,-8.7075 -2.09625,-12.92687z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title ls-cat-title">Building Supplies</h6>
        </a>
      </div>
    </div>
    <div class="category-container desktop-categories ls-quick-links-bar"></div>

    <div id="ls_search_form" class="ls-section-alt">
      <div id="search_bar"></div>
      <h3 class="ls-center">
        Find a Zim Merchant in your preferred Town or City
      </h3>
      <div class="ps-home-banner ps-home-banner--1">
        <section class="ps-container" style="padding-top:20px">
          <SearchForm></SearchForm>
        </section>
      </div>
    </div>
    <div class="ls-section">
      <h5 class="ls-center-text">
        Why send remittances to next of kin as goods and services bought
        directly from merchant & why work with Pahukama trusted runners?
      </h5>
    </div>
    <div class="ps-site-features">
      <div class="ps-container">
        <div class="ps-block--site-features">
          <div class="ps-block__item">
            <div class="ps-block__left"><i class="icon-rocket"></i></div>
            <div class="ps-block__right">
              <h4>Safety & Security</h4>
              <p>
                Every transaction on our platform is secured by trusted
                technology to protect our customers.
              </p>
            </div>
          </div>
          <div class="ps-block__item">
            <div class="ps-block__left"><i class="icon-sync"></i></div>
            <div class="ps-block__right">
              <h4>Frictionless logistics</h4>
              <p>
                We are building an inclusive digital retail ecosystem accessible
                countrywide.
              </p>
            </div>
          </div>
          <div class="ps-block__item">
            <div class="ps-block__left"><i class="icon-credit-card"></i></div>
            <div class="ps-block__right">
              <h4>e-Marketplace Convenience</h4>
              <p>
                Biggest all categories One stop shop accepting secure varied
                payments in both US$ and RTGS
              </p>
            </div>
          </div>
          <div class="ps-block__item">
            <div class="ps-block__left"><i class="icon-rocket"></i></div>
            <div class="ps-block__right">
              <h4>Urban-Rural Contactless Delivery</h4>
              <p>
                Countrywide coverage including rural areas with flexible
                guaranteed Pick up and Delivery options
              </p>
            </div>
          </div>
          <div class="ps-block__item">
            <div class="ps-block__left"><i class="icon-gift"></i></div>
            <div class="ps-block__right">
              <h4>Peace of Mind</h4>
              <p>
                Buy direct from merchants and trusted task rabbits and receive
                order processing & updates
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ps-download-app ls-section">
      <div class="">
        <div class="ps-block--download-app">
          <div class="container">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div class="ps-block__content">
                  <h3>Get Pahukama Apps</h3>
                  <p>
                    Mobile Apps here for placing orders anytime, anyplace and
                    any device.
                  </p>
                  <form class="ps-form--download-app" hidden method="post">
                    <div class="form-group--nest">
                      <input
                        class="form-control"
                        type="Email"
                        placeholder="Email Address"
                      />
                      <button class="ps-btn">Subscribe</button>
                    </div>
                  </form>
                  <p class="download-link">
                    <a href="#"
                      ><img src="/img/google-play.png" alt="Google Play Store"
                    /></a>
                    <a href="#"
                      ><img src="/img/app-store.png" alt="Apple App Store"
                    /></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Vue from "vue";
import ShopCategoryCard from "../../../components/Cards/ShopCategoryCard";
import ShopProductCard from "../../../components/ShopProductCard";
import HomeSlideshow from "../../../components/HomeSlideshow";
import SearchForm from "../../../components/SearchForm";
import HeaderSearch from "../../../components/HeaderSearch";
import HeaderSearch2 from "../../../components/HeaderSearch2";

export default {
  name: "Home",
  components: {
    SearchForm,
    HomeSlideshow,
    ShopProductCard,
    ShopCategoryCard,
    HeaderSearch,
    HeaderSearch2,
  },
  data() {
    return {
      firstCategories: [],
      secondCategories: [],
      thirdCategories: [],
      searchMethod: "Shop Location",
      searchQuery: "",
      loading: true,
      lenosComponentKey: 0,
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.cart,
      auth: (state) => state.auth,
      shop: (state) => state.shop,
    }),
  },
  mounted() {
    var that = this;
    // if (!this.shop.categories) {
    //   axios
    //     .get("shops/categories")
    //     .then(function(res) {
    //       that.firstCategories = res.data.splice(0, 4);
    //       that.thirdCategories = res.data.splice(4, 8);
    //       that.secondCategories = res.data;
    //       that.loading = false;
    //     })
    //     .catch(function(error) {
    //       // handle error
    //       console.log(error);
    //       alert(error);
    //     });
    // } else {
    //   this.firstCategories = this.shop.categories.splice(0, 4);
    //   this.thirdCategories = this.shop.categories.splice(4, 8);
    //   this.secondCategories = this.shop.categories;
    //   this.loading = false;
    // }
  },
  methods: {
    hasCategories(categories) {
      if (categories.length > 0) {
        return categories[0].length > 0;
      }
      return false;
    },
  },
};
</script>
<style lang="css" scoped>
/* @import url(/css/common12d2.css); */

.banner-heading {
  font-weight: 900;
  color: rgb(22, 76, 79);
  font-size: 29px;
  box-sizing: border-box;
  transition: all 400ms ease 0s;
  display: flex;
  flex-direction: column;
}
.banner-heading .banner-heading--1 {
}
.banner-heading .banner-heading--2 {
  font-weight: 300;
}
.banner-heading.banner-title {
  font-weight: 700;
  color: rgb(22, 76, 79);
  font-size: 12px;
  line-height: 1.3;
  box-sizing: border-box;
  margin-top: 0px;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem;
  transition: all 400ms ease 0s;
}
.search-button {
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  min-width: 125px;
  color: #262626;
  max-height: 48px;
  font-size: 12px;
  padding: 8px 20px;
  margin-left: -25px;
}
.input-group-text {
  border-radius: 0px 2px;
  background-color: white;
  border-right: none;
}
.search-input {
  height: 48px;
  /* padding: 0 30px 0 48px; */
  border-left: none;
  border-right: none;
  min-width: 35%;
  clip-path: polygon(0% 0%, 98% 0%, 90% 100%, 0% 100%);
}

.card-frame > .card-overlay {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  border-radius: 5px;
}

.card-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 5rem 6rem;
    background-color: #F1F1F1; */
}

@media screen and (max-width: 768px) {
  .card-section {
    padding: 3rem 3rem;
  }

  .search-input {
    width: 500px;
  }
}

@media screen and (max-width: 575px) {
  .card-section {
    padding: 0rem;
    padding-top: 4rem;
  }
  .search-input {
    width: 500px;
  }
}

.card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .card-container {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 575px) {
  .card-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    row-gap: 0.8rem;
  }
}

.card-frame {
  height: 25rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
}

@media screen and (max-width: 575px) {
  .card-frame {
    height: 10rem;
  }
}

.card-frame[card="fast"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/fast_food.webp");
}

.card-frame[card="fast"] > .card-overlay {
  background-color: rgba(70, 3, 3, 0.6);
}

.card-frame[card="pharmacy"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/pharmacy.webp");
}

.card-frame[card="pharmacy"] > .card-overlay {
  background-color: rgba(60, 3, 70, 0.6);
}

.card-frame[card="electronics"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/electronics.webp");
}

.card-frame[card="electronics"] > .card-overlay {
  background-color: rgba(13, 70, 3, 0.6);
}

.card-frame[card="grocery"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/grocery.webp");
}

.card-frame[card="grocery"] > .card-overlay {
  background-color: rgba(70, 47, 3, 0.6);
}

.card-frame[card="hair_beauty"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/hair_beauty.webp");
}

.card-frame[card="hair_beauty"] > .card-overlay {
  background-color: rgba(70, 3, 3, 0.5);
}

.card-frame[card="house_home"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/house.webp");
}

.card-frame[card="house_home"] > .card-overlay {
  background-color: rgba(38, 95, 109, 0.6);
}

.card-frame[card="construction"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/construction.webp");
}

.card-frame[card="construction"] > .card-overlay {
  background-color: rgba(70, 47, 3, 0.6);
}

.card-frame[card="hardware"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/hardware.webp");
}

.card-frame[card="hardware"] > .card-overlay {
  background-color: rgba(6, 69, 153, 0.6);
}

.card-frame[card="vehicles"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/vehicles.webp");
}

.card-frame[card="vehicles"] > .card-overlay {
  background-color: rgba(211, 42, 42, 0.6);
}

.card-frame[card="solar"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/solar.webp");
}

.card-frame[card="solar"] > .card-overlay {
  background-color: rgba(23, 167, 131, 0.6);
}

.card-frame[card="entertainment"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/entertainment.webp");
}

.card-frame[card="entertainment"] > .card-overlay {
  background-color: rgba(238, 48, 136, 0.6);
}

.card-frame[card="funeral"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/funeral.webp");
}

.card-frame[card="funeral"] > .card-overlay {
  background-color: rgba(63, 68, 65, 0.6);
}

.card-frame[card="gifts"] {
  background-image: url("https://pahukama-site-images.s3.us-east-2.amazonaws.com/treats.webp");
}

.card-frame[card="gifts"] > .card-overlay {
  background-color: rgba(201, 58, 77, 0.6);
}

.card-heading {
  font-size: 1.7em;
  text-align: center;
  padding: 2.5rem 0;
  padding-top: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .card-heading {
    padding: 1.5rem 0;
  }
}

.card-title {
  color: #ffffff;
  font-size: 1.2em !important;
  margin: 0;
  margin-bottom: 0.75rem;
}

@media screen and (max-width: 575px) {
  .card-title {
    font-size: 1em;
  }
}

.card-text {
  padding: 2rem;
  padding-right: 0.5rem;
  flex: 0 0 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 5px;
}

.card-text > p {
  padding: 0;
  font-size: 0.96em;
  margin: auto 0;
  color: #fff;
}

@media screen and (max-width: 575px) {
  .card-text > p {
    display: none;
  }
}

.card-text > .btn-primary {
  padding: 10px;
  min-width: 130px;
  font-size: 15px;
}

@media screen and (max-width: 575px) {
  .card-text {
    flex: 1;
    padding: 0.7rem 1rem;
    justify-content: space-between;
  }
}

.card-logo {
  background-color: #fff;
  position: relative;
  flex: 1;
  -webkit-clip-path: polygon(40% 0, 103% 0%, 102% 100%, 2% 100%);
  clip-path: polygon(40% 0, 103% 0%, 102% 100%, 2% 100%);
  height: 100%;
}

.card-logo > img {
  max-width: 60%;
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}

@media screen and (max-width: 575px) {
  .card-logo {
    display: none;
  }
}

.card-paragraph {
  padding: 0 !important;
}

.promo-runner {
  width: 100%;
  min-height: 6rem;
  display: flex;
  margin: 3rem 0;
  border-radius: 5px;
  padding: 2rem 3rem;
  padding-right: 4rem;
  align-items: center;
  justify-content: space-between;
  background-color: #ad6a00;
  color: #fff !important;
}

@media screen and (max-width: 768px) {
  .promo-runner {
    padding: 1.5rem 1rem;
    flex-direction: column;
    margin: 0.8rem 0;
  }
}

.promo-delivery {
  min-height: 15rem;
  margin: 3.5rem 0;
  border-radius: 5px;
  box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
}

.promo-delivery > .delivery-nav {
  width: 8%;
}

@media screen and (max-width: 575px) {
  .promo-delivery {
    margin: 0.8rem 0;
  }
}

.runner-text {
  margin: 0;
  margin-bottom: 0.4rem;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .runner-text {
    font-size: 1em;
  }
}

@media screen and (max-width: 575px) {
  .runner-text {
    font-size: 0.93em;
  }
}

.runner-info {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.runner-button {
  display: inline-block;
  text-transform: uppercase;
  padding: 0.5rem 0.74rem;
  background-color: white;
  border-radius: 5px;
  color: #000;
}

.runner-button:hover {
  text-decoration: none;
}

@media screen and (max-width: 575px) {
  .runner-button {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
}

.runner-heading {
  margin-bottom: 1rem;
}

@media screen and (max-width: 575px) {
  .runner-heading {
    font-size: 1.1em;
    width: 100%;
  }
}

.promo-delivery {
  min-height: 15rem;
  margin: 3.5rem 0;
  border-radius: 5px;
  box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
}

.promo-delivery > .delivery-nav {
  width: 8%;
}

@media screen and (max-width: 575px) {
  .promo-delivery {
    margin: 0.8rem 0;
  }
}

.runner-text {
  margin: 0;
  margin-bottom: 0.4rem;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .runner-text {
    font-size: 1em;
  }
}

@media screen and (max-width: 575px) {
  .runner-text {
    font-size: 0.93em;
  }
}

.runner-info {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.runner-button {
  display: inline-block;
  text-transform: uppercase;
  padding: 0.5rem 0.74rem;
  background-color: white;
  border-radius: 5px;
  color: #000;
}

.runner-button:hover {
  text-decoration: none;
}

@media screen and (max-width: 575px) {
  .runner-button {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
}

.runner-heading {
  margin-bottom: 1rem;
}

@media screen and (max-width: 575px) {
  .runner-heading {
    font-size: 1.1em;
    width: 100%;
  }
}

.delivery-item {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  min-height: 35rem;
  border-radius: 5px;
  padding: 0 9%;
}

.delivery-item--1 {
  width: 100%;
  /* background-image: url(../images/delivery6efb.webp?5c9178764cdb23596f542cad14f283da); */
  background-position: bottom;
  padding-top: 3rem;
}

.delivery-item--1 .delivery-info {
  color: #000;
}

.delivery-item--1 .delivery-text {
  font-size: 1.4em;
}

.delivery-item--1 .delivery-heading {
  font-size: 1.4em;
  font-family: ClanPro-Medium, Tahoma, Geneva, Verdana, sans-serif;
}

.runner-info > h3,
.runner-info > h5 {
  color: #ffffff !important;
  font-size: 13px;
  font-family: Helvetica, sans-serif;
  font-weight: 400;
}
.runner-info > h3 {
  font-size: 15px;
  font-weight: 700;
}
.show-mobile {
  display: none;
}
@media screen and (max-width: 575px) {
  .promo-delivery {
    min-height: 15rem;
    margin: 3.5rem 0;
    border-radius: 5px;
    box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
  }

  .promo-delivery > .delivery-nav {
    width: 8%;
  }

  @media screen and (max-width: 575px) {
    .promo-delivery {
      margin: 0.8rem 0;
    }
  }

  .runner-text {
    margin: 0;
    margin-bottom: 0.4rem;
    font-weight: 400;
  }

  @media screen and (max-width: 768px) {
    .runner-text {
      font-size: 1em;
    }
  }

  @media screen and (max-width: 575px) {
    .runner-text {
      font-size: 0.93em;
    }
  }

  .runner-info {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .runner-button {
    display: inline-block;
    text-transform: uppercase;
    padding: 0.5rem 0.74rem;
    background-color: white;
    border-radius: 5px;
    color: #000;
  }

  .runner-button:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 575px) {
    .runner-button {
      width: 100%;
      text-align: center;
      margin-top: 1rem;
    }
  }

  .runner-heading {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 575px) {
    .runner-heading {
      font-size: 1.1em;
      width: 100%;
    }
  }

  .delivery-item {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 5px;
    padding: 0 9%;
  }

  .delivery-item--1 {
    width: 100%;
    /* background-image: url(../images/delivery6efb.webp?5c9178764cdb23596f542cad14f283da); */
    background-position: bottom;
    padding-top: 3rem;
  }

  .delivery-item--1 .delivery-info {
    color: #000;
  }

  .delivery-item--1 .delivery-text {
    font-size: 1.4em;
  }

  .delivery-item--1 .delivery-heading {
    font-size: 1.4em;
    font-family: ClanPro-Medium, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  }

  @media screen and (max-width: 575px) {
    .delivery-item--1 {
      padding-top: 28%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .delivery-item--2 {
    padding: 2rem 9%;
    background: #88348f;
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 575px) {
    .delivery-item--2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .delivery-item--3 {
    background-color: #1885b0;
    padding: 1.2rem 9%;
  }

  .delivery-item--3 > .delivery-how--heading {
    color: white;
    font-weight: 900;
    font: 1.7em;
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 575px) {
    .delivery-item--3 > .delivery-how--heading {
      font-size: 14px;
    }
  }

  .delivery-item--3 > .delivery-how--list {
    padding-top: 1rem;
    -moz-column-count: 2;
    -moz-column-rule: 2px solid white;
    -webkit-column-count: 2;
    -webkit-column-rule: 2px solid white;
    column-count: 2;
    -webkit-column-gap: 4rem;
    -moz-column-gap: 4rem;
    column-gap: 4rem;
    font-size: 1.24em;
    font-weight: 400;
    color: white;
    line-height: 1.5;
  }

  @media screen and (max-width: 768px) {
    .delivery-item--3 > .delivery-how--list {
      font-size: 1em;
    }
  }

  @media screen and (max-width: 575px) {
    .delivery-item--3 > .delivery-how--list {
      -moz-column-count: 1;
      -moz-column-gap: 0rem;
      -moz-column-rule: none;
      font-size: 14px;
      -webkit-column-count: 1;
      -webkit-column-rule: none;
      -webkit-column-gap: 0rem;
      column-count: 1;
      column-gap: 0rem;
    }
  }

  @media screen and (max-width: 575px) {
    .delivery-item {
      min-height: 45rem;
    }
  }

  .delivery-info {
    flex: 0 0 60%;
    padding: 0 0.3rem;
    display: flex;
    font-family: "ClanPro-Medium", "Segoe UI", Tahoma, Geneva, Verdana,
      sans-serif;
    flex-direction: column;
    color: white;
    width: 60%;
    align-items: flex-start;
    font-weight: 900;
  }

  @media screen and (max-width: 575px) {
    .delivery-info {
      width: 100%;
      padding: 0 0.5rem;
      align-items: flex-start;
      font-size: 14px;
      text-align: center;
    }
  }

  .delivery-heading {
    letter-spacing: 0.08em;
    font-size: 1.8em;
    margin-top: 1rem;
    margin-bottom: 2.3rem;
    .promo-delivery {
      min-height: 15rem;
      margin: 3.5rem 0;
      border-radius: 5px;
      box-shadow: 1px 2px 13px rgba(0, 0, 0, 0.363);
    }

    .promo-delivery > .delivery-nav {
      width: 8%;
    }

    @media screen and (max-width: 575px) {
      .promo-delivery {
        margin: 0.8rem 0;
      }
    }

    .runner-text {
      margin: 0;
      margin-bottom: 0.4rem;
      font-weight: 400;
    }

    @media screen and (max-width: 768px) {
      .runner-text {
        font-size: 1em;
      }
    }

    @media screen and (max-width: 575px) {
      .runner-text {
        font-size: 0.93em;
      }
    }

    .runner-info {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .runner-button {
      display: inline-block;
      text-transform: uppercase;
      padding: 0.5rem 0.74rem;
      background-color: white;
      border-radius: 5px;
      color: #000;
    }

    .runner-button:hover {
      text-decoration: none;
    }

    @media screen and (max-width: 575px) {
      .runner-button {
        width: 100%;
        text-align: center;
        margin-top: 1rem;
      }
    }

    .runner-heading {
      margin-bottom: 1rem;
    }

    @media screen and (max-width: 575px) {
      .runner-heading {
        font-size: 1.1em;
        width: 100%;
      }
    }

    .delivery-item {
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      min-height: 45rem;
      border-radius: 5px;
      padding: 0 9%;
    }

    .delivery-item--1 {
      width: 100%;
      /* background-image: url(../images/delivery6efb.webp?5c9178764cdb23596f542cad14f283da); */
      background-position: bottom;
      padding-top: 3rem;
    }

    .delivery-item--1 .delivery-info {
      color: #000;
    }

    .delivery-item--1 .delivery-text {
      font-size: 1.4em;
    }

    .delivery-item--1 .delivery-heading {
      font-size: 1.4em;
      font-family: ClanPro-Medium, Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
    }

    @media screen and (max-width: 575px) {
      .delivery-item--1 {
        padding-top: 28%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .delivery-item--2 {
      padding: 2rem 9%;
      background: #88348f;
      display: flex;
      align-items: center;
    }

    @media screen and (max-width: 575px) {
      .delivery-item--2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    .delivery-item--3 {
      background-color: #1885b0;
      padding: 1.2rem 9%;
    }

    .delivery-item--3 > .delivery-how--heading {
      color: white;
      font-weight: 900;
      font: 1.7em;
      margin-bottom: 0.5rem;
    }

    @media screen and (max-width: 575px) {
      .delivery-item--3 > .delivery-how--heading {
        font-size: 14px;
      }
    }

    .delivery-item--3 > .delivery-how--list {
      padding-top: 1rem;
      -moz-column-count: 2;
      -moz-column-rule: 2px solid white;
      -webkit-column-count: 2;
      -webkit-column-rule: 2px solid white;
      column-count: 2;
      -webkit-column-gap: 4rem;
      -moz-column-gap: 4rem;
      column-gap: 4rem;
      font-size: 1.24em;
      font-weight: 400;
      color: white;
      line-height: 1.5;
    }

    @media screen and (max-width: 768px) {
      .delivery-item--3 > .delivery-how--list {
        font-size: 1em;
      }
    }

    @media screen and (max-width: 575px) {
      .delivery-item--3 > .delivery-how--list {
        -moz-column-count: 1;
        -moz-column-gap: 0rem;
        -moz-column-rule: none;
        font-size: 14px;
        -webkit-column-count: 1;
        -webkit-column-rule: none;
        -webkit-column-gap: 0rem;
        column-count: 1;
        column-gap: 0rem;
      }
    }

    @media screen and (max-width: 575px) {
      .delivery-item {
        min-height: 45rem;
      }
    }

    .delivery-info {
      flex: 0 0 60%;
      padding: 0 0.3rem;
      display: flex;
      font-family: "ClanPro-Medium", "Segoe UI", Tahoma, Geneva, Verdana,
        sans-serif;
      flex-direction: column;
      color: white;
      width: 60%;
      align-items: flex-start;
      font-weight: 900;
    }

    @media screen and (max-width: 575px) {
      .delivery-info {
        width: 100%;
        padding: 0 0.5rem;
        align-items: flex-start;
        font-size: 14px;
        text-align: center;
      }
    }

    .delivery-heading {
      letter-spacing: 0.08em;
      font-size: 1.8em;
      margin-top: 1rem;
      margin-bottom: 2.3rem;
    }

    @media screen and (max-width: 768px) {
      .delivery-heading {
        font-size: 1.6em;
        margin: 0;
        margin-bottom: 1.2rem;
      }
    }

    @media screen and (max-width: 575px) {
      .delivery-heading {
        font-size: 17px;
        font-weight: 900;
        width: 100%;
      }
    }

    .delivery-text {
      font-weight: 400;
      font-size: 1em;
      margin-bottom: 0.6rem;
      letter-spacing: 0.032em;
    }

    @media screen and (max-width: 575px) {
      .delivery-text {
        margin-bottom: 1rem;
        font-size: 12px;
        width: 100%;
      }
    }

    .delivery-image {
      position: absolute;
      top: 50%;
      right: 15%;
      transform: translate(20%, -50%);
      z-index: 5;
      width: 36%;
      margin: 0 auto;
    }

    .delivery-image > img {
      width: 100%;
    }

    @media screen and (max-width: 575px) {
      .delivery-image {
        width: 65%;
        margin-top: 0.5rem;
        transform: unset;
        position: static;
      }
    }

    @media screen and (max-width: 575px) {
      .delivery-button {
        margin-top: 1rem;
        text-align: center;
      }
    }

    .delivery-highlight {
      font-weight: 900;
    }
  }

  @media screen and (max-width: 768px) {
    .delivery-heading {
      font-size: 1.6em;
      margin: 0;
      margin-bottom: 1.2rem;
    }
  }

  .delivery-text {
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 0.6rem;
    letter-spacing: 0.032em;
  }

  @media screen and (max-width: 575px) {
    .delivery-text {
      margin-bottom: 1rem;
      font-size: 12px;
      width: 100%;
    }
  }

  .delivery-image {
    position: absolute;
    top: 50%;
    right: 15%;
    transform: translate(20%, -50%);
    z-index: 5;
    width: 36%;
    margin: 0 auto;
  }

  .delivery-image > img {
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    .delivery-image {
      width: 65%;
      margin-top: 0.5rem;
      transform: unset;
      position: static;
    }
  }

  @media screen and (max-width: 575px) {
    .delivery-button {
      margin-top: 1rem;
      text-align: center;
    }
  }

  .delivery-highlight {
    font-weight: 900;
  }

  .delivery-item--1 {
    padding-top: 28%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.delivery-item--2 {
  padding: 2rem 9%;
  background: #88348f;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 575px) {
  .delivery-item--2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.delivery-item--3 {
  background-color: #1885b0;
  padding: 1.2rem 9%;
}

.delivery-item--3 > .delivery-how--heading {
  color: white;
  font-weight: 900;
  font: 1.7em;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 575px) {
  .delivery-item--3 > .delivery-how--heading {
    font-size: 14px;
  }
}

.delivery-item--3 > .delivery-how--list {
  padding-top: 1rem;
  -moz-column-count: 2;
  -moz-column-rule: 2px solid white;
  -webkit-column-count: 2;
  -webkit-column-rule: 2px solid white;
  column-count: 2;
  -webkit-column-gap: 4rem;
  -moz-column-gap: 4rem;
  column-gap: 4rem;
  font-size: 1.24em;
  font-weight: 400;
  color: white;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .delivery-item--3 > .delivery-how--list {
    font-size: 1em;
  }
}
.shop-direct {
  display: none;
}
@media screen and (max-width: 575px) {
  .delivery-item--3 > .delivery-how--list {
    -moz-column-count: 1;
    -moz-column-gap: 0rem;
    -moz-column-rule: none;
    font-size: 14px;
    -webkit-column-count: 1;
    -webkit-column-rule: none;
    -webkit-column-gap: 0rem;
    column-count: 1;
    column-gap: 0rem;
  }
}

@media screen and (max-width: 575px) {
  .delivery-item {
    padding: 2rem 0.5rem;
    min-height: 35rem;
  }
  .delivery-info > h2 {
    font-size: 21px;
    text-align: center;
    width: 100%;
  }
  .delivery-info > h5 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  .delivery-image {
    max-width: 40%;
  }
  .shop-direct {
    display: inline;
  }

  .banner-content {
    display: none;
  }
}

.delivery-info {
  flex: 0 0 60%;
  padding: 0 0.3rem;
  display: flex;
  font-family: "ClanPro-Medium", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  flex-direction: column;
  color: white;
  width: 60%;
  align-items: flex-start;
  font-weight: 900;
}

@media screen and (max-width: 575px) {
  .delivery-info {
    width: 100%;
    padding: 0 0.5rem;
    align-items: flex-start;
    font-size: 14px;
    text-align: center;
  }
  .delivery-info > h5 {
    text-align: center;
    width: 100%;
  }
}

.delivery-heading {
  letter-spacing: 0.08em;
  font-size: 1.8em;
  margin-top: 1rem;
  margin-bottom: 2.3rem;
}

@media screen and (max-width: 768px) {
  .delivery-heading {
    font-size: 1.6em;
    margin: 0;
    margin-bottom: 1.2rem;
  }
}

@media screen and (max-width: 575px) {
  .delivery-heading {
    font-size: 17px;
    font-weight: 900;
    width: 100%;
  }
}

.delivery-text {
  font-weight: 400;
  font-size: 1em;
  margin-bottom: 0.6rem;
  letter-spacing: 0.032em;
}

@media screen and (max-width: 575px) {
  .delivery-text {
    margin-bottom: 1rem;
    font-size: 12px;
    width: 100%;
  }
}

.delivery-image {
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translate(20%, -50%);
  z-index: 5;
  width: 28%;
  margin: 0 auto;
}

.delivery-image > img {
  width: 100%;
}

@media screen and (max-width: 575px) {
  .delivery-image {
    width: 65%;
    margin-top: 0.5rem;
    transform: unset;
    position: static;
  }
  .show-mobile {
    display: block;
  }
}

@media screen and (max-width: 575px) {
  .delivery-button {
    margin-top: 1rem;
    text-align: center;
  }
  .carousel-mobile-image {
    width: 100%;
    object-fit: cover;
  }
}

.delivery-highlight {
  font-weight: 900;
}

/*search box css start here*/
.search-sec {
  padding: 2rem;
  top: -500px;
}
.search-slt {
  display: block;
  width: 100%;
  /* font-size: 0.875rem; */
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  /* height: calc(3rem + 2px) !important; */
  border-radius: 0;
}
.wrn-btn {
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  text-transform: capitalize;
  height: 100% !important;
  border-radius: 0;
  color: black;
  border: none;
  background-color: #f9a51e;
}
@media (min-width: 992px) {
  .search-sec {
    /* position: relative; */
    margin-top: -100px;
    z-index: 10;
    background: black;
  }

  .slider-image {
    height: 600px !important;
  }
}

@media (max-width: 992px) {
  .search-sec {
    background: black;
  }

  .slider-image {
    height: 600px !important;
  }
}
.slider-image {
  height: 600px !important;
}

@media (max-width: 600px) {
  .slider-image {
    height: 100% !important;
  }
}

/* @import url('/css/common12d2.css'); */
.category-container {
  display: flex;
}

.mobile-categories {
  display: none;
}
@media screen and (max-width: 575px) {
  .mobile-categories {
    display: inline-flex;
    overflow-x: scroll;
    width: 100%;
  }

  .desktop-categories {
    /*display: none; */
  }
}
@media screen and (max-width: 575px) {
  .category-container {
    /* overflow-x: scroll;
    touch-action: pan-x; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.category-item {
  flex: 1;
  padding: 6rem 3;
  border: 1px solid rgba(38, 38, 38, 0.132);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: all 0.3s ease-in;
}

@media screen and (max-width: 575px) {
  .category-item {
    min-width: 12rem;
    padding: 10px 0;
  }
}

.category-item:hover {
  cursor: pointer;
}

.category-item:hover > * {
  color: #757575;
  -webkit-text-stroke: 0.5px #f79b07;
}

.category-title {
  margin: 0;
  font-size: 2rem;
  color: #747474;
  text-shadow: 1px 4px 6px #def, 0 0 0 #000, 1px 4px 6px #def;
  text-align: center;
}

.category-icon {
  width: 50px;
  height: 54px;
}

@media screen and (max-width: 575px) {
  .category-icon {
    width: 30px;
    height: 34px;
  }
  .category-title {
    font-size: 10px;
  }
  .col-cat4 {
    padding: 0;
  }
  .ps-search--mobile {
    padding: 1rem 2rem 2rem 2rem;
  }
}
</style>
